<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    width="550px"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      currentCompany: null,
      config: [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // lang:所属行业
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
          tagEvents: {
            change(ids) {
              if (ids[0] === 'OTHER') {
                self.config[2].hidden = false
              } else {
                self.config[2].hidden = true
              }
            },
          },
        },
        // lang:其他行业
        {
          label: this.$t('companyManage.customIndustry'),
          prop: 'customIndustry',
          hidden: true,
          rules: {
            noNull: true,
          },
        },
        // lang:地址
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.address'),
          prop: 'address',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:详细地址
        {
          label: this.$t('companyManage.addressDetail'),
          prop: 'addressDetail',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 2,
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
          rules: {
            noNull: true,
          },
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
          rules: {
            noNull: true,
            type: 'mobileOrPhone',
          },
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
          rules: {
            noNull: true,
            type: 'email',
          },
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 语言处理
          this.dictFindTree(
            'COMPANY_INDUSTRY',
            this.tableRow.industry ? this.tableRow.industry.language : language
          )
          this.dictFindTree(
            'ADDRESS',
            this.tableRow.address ? this.tableRow.address.language : language
          )
          // 行业处理
          if (!this.tableRow.industry) {
            this.formData.industry = ['OTHER']
            this.config[2].hidden = false
          } else {
            this.formData.industry = this.$util.getTreeIds(
              this.tableRow.industry
            )
            this.config[2].hidden = true
          }
          // 地址处理
          this.formData.address = this.$util.getTreeIds(this.tableRow.address)
        } else {
          this.dictFindTree('COMPANY_INDUSTRY', language)
          this.dictFindTree('ADDRESS', language)
        }
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        if (dictType === 'COMPANY_INDUSTRY') {
          data.push({
            id: 'OTHER',
            value: '其他',
          })
        }
        if (dictType === 'COMPANY_INDUSTRY') {
          this.config[1].tagProps.options = data || []
        } else {
          this.config[3].tagProps.options = data || []
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理行业
      const industry = formData.industry.pop()
      if (industry === 'OTHER') formData.industry = null
      else formData.industry = { id: industry }
      // 处理地址
      formData.address = { id: formData.address.pop() }
      this.editMethod(formData)
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>